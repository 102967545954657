import React from "react";
import classNames from "classnames";
import { Container, Text, Image } from "@atoms";
import { NextArrow } from "@molecules";

const HeroImage = ({ heading, description, largerHeadingSpace, image }) => {
  return (
    <section className="py-24 md:py-28">
      <Container variant="xl" frame>
        <div
          className={classNames("flex flex-col", {
            "gap-6 sm:gap-8 lg:gap-10": !largerHeadingSpace,
            "sm:gap-30 gap-16 lg:gap-40": largerHeadingSpace,
          })}
        >
          <div className="flex flex-col items-center justify-between sm:flex-row">
            <div>
              <Text variant="h1" className="text-white">
                {heading}
              </Text>
              <NextArrow className="mt-8" />
            </div>

            {image && (
              <Image
                image={image}
                className="max-w-xs sm:max-w-sm xxl:max-w-lg"
                ixParams={{ fit: "max" }}
                // objectFit="contain"
              />
            )}
          </div>

          <div className="max-w-2xl">
            <Text variant="h6" className="text-white">
              {description}
            </Text>
          </div>
        </div>
      </Container>
    </section>
  );
};

HeroImage.defaultProps = {};

export default HeroImage;
